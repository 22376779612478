/***
 * Controls are rendered in the property panel from the property config
 * Controls are higher order components that update a widgets property
 */
import _ from "lodash";
import { Component } from "react";
import { ItemKinds } from "legacy/pages/Editor/PropertyPane/ItemKindConstants";
import { Flags } from "store/slices/featureFlags";
import { ApiError } from "utils/error/error";
import type { PropertyPaneControlConfig } from "legacy/constants/PropertyControlConstants";

export enum ControlLayout {
  HORIZONTAL_FLEX = "HORIZONTAL_FLEX",
  VERTICAL_FLEX = "VERTICAL_FLEX",
  GRID = "GRID",
}

interface BaseControl<P extends ControlProps, S = unknown>
  extends Component<P, S> {
  updateProperty(propertyName: string, propertyValue: any): void;
  deleteProperties(propertyPaths: string[]): void;
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging
abstract class BaseControl<
  P extends ControlProps,
  S = unknown,
> extends Component<P, S> {
  updateProperty(propertyName: string, propertyValue: any) {
    if (!_.isNil(this.props.onPropertyChange))
      this.props.onPropertyChange(propertyName, propertyValue);
  }
  deleteProperties(propertyPaths: string[]) {
    if (this.props.deleteProperties) {
      this.props.deleteProperties(propertyPaths);
    }
  }
  updateProperties(updates: Record<string, unknown>) {
    if (!_.isNil(this.props.updateProperties)) {
      this.props.updateProperties(updates);
    }
  }
}

export interface ControlBuilder<T extends ControlProps> {
  buildPropertyControl(controlProps: T): JSX.Element;
  getControlLayout?: (props: {
    propertyValue: any;
    panelWidth: number;
    itemName: string;
  }) => ControlLayout;
}

export interface ControlProps extends ControlData, ControlFunctions {
  key?: string;
  additionalAutoComplete?: Record<string, Record<string, unknown>>;
  getDynamicAdditionalAutocomplete?: (
    props: ControlProps,
  ) => Record<string, Record<string, unknown>>;
  autoCreateFirstArrayEntry: boolean;
  dataTest?: string;
  featureFlags: Flags;
  isDisabled?: boolean;
  isV2Panel?: boolean;
}
export interface ControlData extends PropertyPaneControlConfig {
  propertyName: string;
  propertyValue?: any;
  themeValue?: any;
  isValid: boolean;
  expected: string;
  exampleData: string;
  validationMessage?: string;
  apiErrors?: ApiError[];
  widgetProperties: any;
  docLink: string;
}
export interface ControlFunctions {
  onPropertyChange?: (propertyName: string, propertyValue: any) => void;
  updateProperties?: (updates: Record<string, unknown>) => void;
  openNextPanel: (props: any, onPanelClose?: () => void) => void;
  deleteProperties: (propertyPaths: string[]) => void;
  onFocus: () => void;
  onBlur: () => void;
  setControlLayout?: (layout: ControlLayout) => void;
  getPanelWidth?: () => number;
  getPanelContainer?: () => HTMLDivElement | undefined;
  getItemId?: (props: any) => string;
  getItemKind?: (props: any) => ItemKinds;
  getPopupWrapperElem?: () => HTMLDivElement | null;
}

export default BaseControl;
