import { CurrencyList } from "legacy/constants/FormatConstants";
import {
  PropsPanelCategory,
  type PropertyPaneConfig,
} from "legacy/constants/PropertyControlConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import { NUMBER_FORMATTING_OPTIONS } from "legacy/utils/FormatUtils";
import { ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT } from "pages/Editors/AppBuilder/Sidebar/PropertyControlCommons";
import {
  labelPositionProperty,
  sizeSection,
  visibleProperties,
} from "../basePropertySections";
import { getPopoverConfig } from "../eventHandlerPanel";
import { updateHeightOnAddOrRemove } from "../propsPanelUtils";
import { textStyleControls } from "../styleProperties";
import { hasIconSupport, isNumericInput } from "./InputComponent";
import {
  DEFAULT_INPUT_WIDGET_INPUT_STYLE_VARIANT,
  DEFAULT_INPUT_WIDGET_LABEL_STYLE_VARIANT,
  INPUT_TYPES_WITH_MINMAX,
  INPUT_TYPE_OPTIONS,
  InputTypes,
  InputWidgetProps,
} from "./InputWidgetConstants";

const config: PropertyPaneConfig[] = [
  {
    sectionName: "General",
    sectionCategory: PropsPanelCategory.Content,
    children: [
      {
        helpText: "Sets a label text for the input",
        propertyName: "label",
        label: "Label",
        controlType: "INPUT_TEXT",
        placeholderText: "Enter label text",
        isBindProperty: true,
        isTriggerProperty: false,
        visibility: "SHOW_NAME",
        isRemovable: true,
        defaultValue: "Label",
        updateHook: updateHeightOnAddOrRemove,
      },
      {
        helpText: "Changes the type of data captured in the input",
        propertyName: "inputType",
        label: "Data type",
        controlType: "DROP_DOWN",
        options: INPUT_TYPE_OPTIONS,
        isBindProperty: false,
        isTriggerProperty: false,
      },
    ],
  },
  sizeSection(),
  {
    sectionName: "Advanced",
    children: [
      {
        helpText:
          "Sets the default text of the component. The text is updated if the default text changes",
        propertyName: "defaultText",
        label: "Default value",
        controlType: "INPUT_TEXT",
        placeholderText: "Enter default text",
        isBindProperty: true,
        isTriggerProperty: false,
        propertyCategory: PropsPanelCategory.Content,
      },
      {
        helpText: "Sets a placeholder text for the input",
        propertyName: "placeholderText",
        label: "Placeholder text",
        controlType: "INPUT_TEXT",
        placeholderText: "Input text",
        isBindProperty: true,
        isTriggerProperty: false,
        propertyCategory: PropsPanelCategory.Appearance,
      },
      {
        propertyName: "icon",
        label: "Icon",
        helpText: "Select an icon",
        controlType: "ICON_SELECTOR",
        customJSControl: "CODE_EDITOR_ICON_SELECTOR",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        hidden: ({ inputType }: InputWidgetProps) => !hasIconSupport(inputType),
        propertyCategory: PropsPanelCategory.Appearance,
      },
      {
        propertyName: "iconPosition",
        helpText: "Sets the alignment of the icon",
        label: "Icon position",
        controlType: "RADIO_BUTTON_GROUP",
        options: [
          {
            icon: "ICON_LEFT_ALIGN",
            value: "LEFT",
            label: "Left",
          },
          {
            icon: "ICON_RIGHT_ALIGN",
            value: "RIGHT",
            label: "Right",
          },
        ],
        isBindProperty: false,
        isTriggerProperty: false,
        hidden: ({ inputType, icon }: InputWidgetProps) =>
          !hasIconSupport(inputType) || icon == null,
        propertyCategory: PropsPanelCategory.Appearance,
      },
      labelPositionProperty({ orientation: "legacy" }),
      ...textStyleControls({
        key: "labelProps",
        propertyName: "label",
        textStyleLabel: "Label text style",
        colorLabelText: "Label color",
        defaultVariant: DEFAULT_INPUT_WIDGET_LABEL_STYLE_VARIANT,
      }),
      ...textStyleControls({
        key: "inputProps",
        textStyleLabel: "Input text style",
        colorLabelText: "Input text color",
        defaultVariant: DEFAULT_INPUT_WIDGET_INPUT_STYLE_VARIANT,
      }),
      {
        propertyName: "currency",
        label: "ISO currency code",
        helpText: "The three letter ISO 4217 currency code of the input",
        controlType: "DROP_DOWN",
        defaultValue: "USD",
        options: CurrencyList.map((currency) => ({
          label: currency,
          value: currency,
        })),
        hidden: ({ inputType }: InputWidgetProps) =>
          inputType !== InputTypes.CURRENCY,
        isBindProperty: true,
        isJSConvertible: true,
        isTriggerProperty: false,
        propertyCategory: PropsPanelCategory.Appearance,
      },
      {
        propertyName: "currencyCodeDisplay",
        label: "Currency code display",
        helpText:
          "The display for currency codes. If a symbol is unavailable, the display will fall back to the code",
        controlType: "RADIO_BUTTON",
        defaultValue: "symbol",
        options: [
          {
            name: "Symbol",
            value: "symbol",
          },
          {
            name: "ISO Code",
            value: "iso_code",
          },
        ],
        hidden: ({ inputType }: InputWidgetProps) =>
          inputType !== InputTypes.CURRENCY,
        isBindProperty: false,
        isTriggerProperty: false,
        propertyCategory: PropsPanelCategory.Appearance,
      },
      {
        propertyName: "numberFormatting",
        label: "Number formatting",
        controlType: "DROP_DOWN",
        hidden: (props: InputWidgetProps) =>
          props.inputType !== InputTypes.NUMBER &&
          props.inputType !== InputTypes.CURRENCY,
        defaultValue: undefined,
        options: NUMBER_FORMATTING_OPTIONS,
        isBindProperty: false,
        isTriggerProperty: false,
        helpText: `The formatting for displaying the number`,
        propertyCategory: PropsPanelCategory.Interaction,
      },
      {
        propertyName: "minimumFractionDigits",
        label: "Minimum fraction digits",
        controlType: "INPUT_TEXT",
        hidden: ({ inputType }: InputWidgetProps) =>
          inputType !== InputTypes.NUMBER &&
          inputType !== InputTypes.PERCENTAGE &&
          inputType !== InputTypes.CURRENCY,
        isBindProperty: true,
        isTriggerProperty: false,
        placeholderText: "Input a number between 0-20",
        helpText: `The minimum number of digits after the decimal. Values with fewer than the minimum number of decimals will be normalized accordingly`,
        propertyCategory: PropsPanelCategory.Interaction,
      },
      {
        propertyName: "maximumFractionDigits",
        label: "Maximum fraction digits",
        controlType: "INPUT_TEXT",
        hidden: ({ inputType }: InputWidgetProps) =>
          inputType !== InputTypes.NUMBER &&
          inputType !== InputTypes.PERCENTAGE &&
          inputType !== InputTypes.CURRENCY,
        isBindProperty: true,
        isTriggerProperty: false,
        placeholderText: "Input a number between 0-20",
        helpText: `The maximum number of digits after the decimal. Values with more than this number of decimals will be rounded`,
        propertyCategory: PropsPanelCategory.Interaction,
      },
      {
        propertyName: "stepper",
        label: "Stepper",
        controlType: "SWITCH",
        hidden: ({ inputType }: InputWidgetProps) => !isNumericInput(inputType),
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        helpText:
          "Display a stepper in the input for users to adjust the value via arrows",
        propertyCategory: PropsPanelCategory.Interaction,
      },
      {
        propertyName: "stepSize",
        label: "Step size",
        controlType: "INPUT_TEXT",
        hidden: ({ inputType }: InputWidgetProps) => !isNumericInput(inputType),
        defaultValue: 1,
        isBindProperty: true,
        isTriggerProperty: false,
        helpText:
          "The increment used when adjusting the value via the UI stepper or keyboard arrow keys",
        propertyCategory: PropsPanelCategory.Interaction,
      },
      {
        helpText: ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT,
        propertyName: "animateLoading",
        label: "Loading animation",
        controlType: "SWITCH",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        propertyCategory: PropsPanelCategory.Appearance,
      },
      ...visibleProperties({ useJsExpr: false }),
      {
        helpText: "Disables input to this component",
        propertyName: "isDisabled",
        label: "Disabled",
        controlType: "SWITCH",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: VALIDATION_TYPES.BOOLEAN,
        propertyCategory: PropsPanelCategory.Interaction,
      },
    ],
  },
  {
    sectionName: "Actions",
    sectionCategory: PropsPanelCategory.EventHandlers,
    children: [
      getPopoverConfig(
        "onTextChanged",
        "Triggers an action when the text is changed",
      ),
      getPopoverConfig(
        "onSubmit",
        "Triggers an action on submit (when the enter key is pressed)",
      ),
      getPopoverConfig("onFocus", "Triggers an action on input focus"),
      getPopoverConfig("onFocusOut", "Triggers an action on input blur"),
    ],
  },
  {
    sectionName: "Validation",
    sectionCategory: PropsPanelCategory.Interaction,
    children: [
      {
        propertyName: "isRequired",
        label: "Required",
        helpText: "Makes input to the component mandatory",
        controlType: "SWITCH",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
      },
      {
        propertyName: "minLength",
        label: "Min length",
        controlType: "INPUT_TEXT",
        inputType: "NUMBER",
        hidden: ({ inputType }: InputWidgetProps) =>
          !INPUT_TYPES_WITH_MINMAX.includes(inputType),
        isBindProperty: true,
        isTriggerProperty: false,
      },
      {
        propertyName: "maxLength",
        label: "Max length",
        controlType: "INPUT_TEXT",
        inputType: "NUMBER",
        hidden: ({ inputType }: InputWidgetProps) =>
          !INPUT_TYPES_WITH_MINMAX.includes(inputType),
        isBindProperty: true,
        isTriggerProperty: false,
      },
      {
        propertyName: "customErrorMessage",
        label: "Error",
        helpText:
          "Sets a custom message to display in a popover when validation doesn't pass",
        controlType: "INPUT_TEXT",
        placeholderText: "Enter validation message",
        isBindProperty: true,
        isTriggerProperty: false,
      },
      {
        propertyName: "customValidationRule",
        label: "Validation",
        helpText: "Sets a custom validation rule for the input",
        controlType: "INPUT_TEXT",
        placeholderText: "{{/^[a-z]+$/.test(Input1.value)}}",
        isBindProperty: true,
        isTriggerProperty: false,
      },
    ],
  },
];

export default config;
